import React from 'react'
import './style.scss'
import Layout from '../layouts'


const BookBarPageTemplate = ({ pathContext }) => (
  <Layout
    withFooter={false}
  >
    <div className="container">
      <iframe
        title="privateaser-booking"
        id="mgb-booking-widget"
        src={`https://www.privateaser.com/booking-widget/mistergoodbeer/venue/${pathContext.privateaserId}`}
        frameBorder="0"
        width="100%"
      />
    </div>
  </Layout>
)

export default BookBarPageTemplate
